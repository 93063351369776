import React from "react";
import LeadForm from "../components/Interactive/leadForm";
import Layout from '../components/layout'
import {Box} from "@chakra-ui/react";
import leadFormBgImage from "../assets/images/ap-banner.svg";
import {useCookies} from "react-cookie";
import {LP_SLUG_COOKIE_KEY} from "../components/Header";
import Seo from "../components/Seo";

const PRODUCT_COOKIE_NAME = "lpProductChoice";

const changeFirstPageStyle = (formDef) => {
    let firstPage = formDef?.find(page => page?.pagePrompt === "Tell us more about yourself");
    if (!!firstPage) {
        firstPage.questions[0].color = "gray3d";
        firstPage.questions[0].backgroundColor = "white";
        firstPage.questions[0].width = "270px";
    }
}
export const ppcSlugToFormValue = {
    act: "ACT",
    sat: "SAT",
    ["academic-tutoring"]: "Academic tutoring",
    other: "other"
};

const productNameToFormValue = {
    ["1-on-1"]: "Personalized 1-on-1 tutoring",
    ["Small group"]: "Structured small group tutoring"
}

const LeadFormPage = ({location, data, pageContext}) => {

    const[cookies] = useCookies([LP_SLUG_COOKIE_KEY, PRODUCT_COOKIE_NAME])

    let ppcSlug = cookies[LP_SLUG_COOKIE_KEY];
    let goalFormValue = ppcSlugToFormValue[ppcSlug];
    let isUnifiedFunnel = !!ppcSlug && !! goalFormValue;

    let product = cookies[PRODUCT_COOKIE_NAME];
    let productFormValue = productNameToFormValue[product];

    let skippedQuestions = ["How can we help?"];
    let addedFormFields = {
        exam: goalFormValue
    }
    if (!!product && !!productFormValue) {
        skippedQuestions.push("What type of program are you looking for?");
        addedFormFields.what_prompted_you_to_look_for_tutoring = productFormValue;
    }
    const seo = {
        title: 'Expert Tutoring for ' + (goalFormValue || 'Tutoring') + ' | Enhanced Prep',
        metaDescription: 'Enhanced Prep matches you with expert ' + (goalFormValue || '') + ' tutors who work with you on a personalized study plan. 95% of students are accepted into their top schools.',
        crazyEggScript: <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0114/9898.js" async="async" ></script>
    };
    const {header, footer} = pageContext;
    return  <Layout header={header} footer={footer}>
        <Seo data={seo} location={location} />
        <Box bgImg={leadFormBgImage}>
        <LeadForm data={{formType: 'homepage',
                        title: `Get more information about ${!!goalFormValue? goalFormValue + "®" : ''} tutoring`,
                        isUnifiedFunnel: isUnifiedFunnel,
                        skippedPages: isUnifiedFunnel && skippedQuestions,
                        formDefOverrider: isUnifiedFunnel ? (formDef) => changeFirstPageStyle(formDef) : null,
                        addedFormFields: (isUnifiedFunnel) && addedFormFields}} />
        </Box>
    </Layout>

}

export default LeadFormPage;
